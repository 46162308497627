@import '../declarations';

cx-page-layout.LandingPageTemplate {
  .StartCategoryPuffs {
    @extend .container;

    py-puff-collection {
      .puff-collection-holder {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
        border-bottom: 1px solid var(--py-color-grey-light);
        margin-bottom: 5rem;
      }
    }
  }

  .EditableStartContent {
    margin-bottom: 4rem;
  }

  .StartContent,
  .EditableStartContent {
    @extend .container;

    py-label-text {
      margin-top: 7rem;
    }
  }

  .StartBanner {
    py-banner {
      .banner-holder {
        height: 530px;

        @include media-breakpoint-down(sm) {
          height: 440px;

          .banner-text-holder div.content {
            font-size: 16px;
            margin: 30px 0;
          }
        }
      }
    }
  }
}
