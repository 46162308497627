cx-page-layout.ContentPageTemplateWide {
  .Content,
  .Content_2,
  py-page-time-created {
    @extend .container;
  }
}

cx-page-layout.ContentPageTemplate2ColsWide {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  .Content_1 {
    width: 100%;
    flex-basis: 685px;
    max-width: 685px;
    margin-bottom: 50px;
  }

  .Content_2 {
    width: 100%;
    margin-left: 100px;
    margin-bottom: 50px;
    flex-basis: 325px;
    max-width: 325px;
  }

  .BannerWide_1,
  .BannerWide_2 {
    width: 100%;
  }

  .Content_3,
  .Content_4 {
    @extend .container;
  }

  py-page-time-created {
    flex: 0 1 100%;
    padding: 0;
    padding-bottom: 10px;
    padding-left: 20px;

    span {
      max-width: 1170px;
      margin: 0 auto;
    }
  }

  @include media-breakpoint-down(lg) {
    .Content_1 {
      flex-basis: 573px;
      max-width: 573px;
    }

    .Content_2 {
      margin-left: 10px;
      flex-basis: 357px;
      max-width: 357px;
    }
  }

  @include media-breakpoint-up(md) {
    py-paragraph {
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
    padding: 0 15px;
    max-width: 720px;
    margin: 0 auto;

    cx-page-slot.Content_1,
    cx-page-slot.Content_2,
    cx-page-slot.Content_3,
    cx-page-slot.Content_4 {
      padding: 0;
      max-width: none;
      margin: 0 auto;
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: 540px;
    padding: 0 30px;
  }
}

cx-page-layout.ContentPageTemplate {
  .Content,
  py-page-time-created {
    @extend .col-12;

    @extend .col-md-10;
    @extend .offset-md-1;

    @extend .col-lg-8;
    @extend .offset-lg-2;

    @extend .col-xl-6;
    @extend .offset-xl-3;
  }
}

cx-page-layout.ContentPageTemplate2Cols {
  .Content_1 {
    @extend .col-7;
  }

  .Content_2 {
    @extend .col-5;
    @extend .col-xl-4;
    @extend .offset-xl-1;

    @include media-breakpoint-up(lg) {
      padding-top: 30px;
      py-paragraph {
        display: block;
      }
    }
  }

  py-page-time-created {
    flex: 0 1 100%;
    @extend .col-7;
    padding: 0;
  }
}

cx-page-layout.ContentPageTemplate3Cols {
  .Content_1 {
    @extend .col-2;
  }

  .Content_2 {
    @extend .col-8;
  }

  .Content_3 {
    @extend .col-2;
  }

  py-page-time-created {
    flex: 0 1 100%;
    @extend .offset-2;
  }
}

cx-page-layout {
  py-paragraph,
  py-image {
    .component-holder {
      @extend .py-4;
      @extend .col-12;
    }
  }
}

//Use .row with no negative margin for components to work in smartedit. (ESVCX-2005)
cx-page-layout {
  py-paragraph,
  py-image,
  py-link,
  py-link-collection,
  py-puff-collection,
  py-dynamic-puff-collection,
  py-banner-collection,
  py-footer-navigation,
  py-social-link,
  py-alphabetical-link-container,
  py-mail-form,
  py-banner {
    @extend .row;
    @extend .no-gutters;
  }
}

cx-page-layout.ContentPageTemplate2Cols,
cx-page-layout.ContentPageTemplate3Cols {
  @extend .container;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  cx-page-slot.Content_1,
  cx-page-slot.Content_3 {
    padding: 0px;
  }

  @include media-breakpoint-down(md) {
    display: block;

    cx-page-slot.Content_1,
    cx-page-slot.Content_2,
    cx-page-slot.Content_3 {
      max-width: none;
      margin: 0 auto;
      padding: 0;
    }
  }
}

cx-page-layout.footer {
  background-color: var(--py-color-primary);
}

cx-page-layout.header {
  min-height: 48px;
}

cx-page-layout.LandingPageTemplate,
cx-page-layout.ContentPageTemplate,
cx-page-layout.ContentPageTemplate2Cols,
cx-page-layout.ContentPageTemplate3Cols {
  min-height: 500px;
  padding-bottom: 20px;
}

cx-page-layout.ContentPageTemplate2ColsWide,
cx-page-layout.ContentPageTemplateWide {
  min-height: 500px;
}
