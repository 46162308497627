@import '../../../../../node_modules/bootstrap/scss/variables';
@import 'spacer-variables';

/* Bootstrap variable overrides */
$headings-margin-bottom: 0;
$paragraph-margin-bottom: 0;
$label-margin-bottom: 0;
$input-height: 50px;
$input-padding-x: 17px;
$input-border-color: var(--py-color-grey-light);
$input-color: var(--py-color-black);
$input-disabled-bg: var(--py-color-grey-lighter);
$line-height-base: 1.5;
$headings-font-family: var(--py-font-secondary-bold);
$headings-line-height: 1.5;
$headings-color: var(--py-color-black);

/* Custom global variables */
$default-input-min-height: $input-height;
$scroll-box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.17);
$padding-y: 0.7em;
$padding-x: 1.8em;
$icon-size: 1.4em;
$icon-padding: #{$padding-y - $icon-size + 1em};
$line-height: 1.5em;

$button-shadow-shape: 0 2px 4px;
$button-shadow-shape-large: 0 4px 8px;
$box-shadow: 0 5px 10px 0 rgba(18, 54, 84, 0.25);
$box-shadow-button: 0px 0px 10px rgba(100, 101, 104, 0.3);
