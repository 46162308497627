cx-page-layout.RegistrationPageTemplate {
  display: flex;

  .Section1 {
    width: 100%;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: center;
  }
}
