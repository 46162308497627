@import 'declarations';
@import '../../../node_modules/bootstrap/scss/grid';
@import '../../../node_modules/bootstrap/scss/utilities/spacing';

@import './blank-page-template';
@import './catalog-page-template';
@import './cart-page-template';
@import './checkout-page-template';
@import './order-confirmation-page-template';
@import './login-page-template';
@import './brand-page-template';
@import './my-page-template';
@import './registration-page-template';
@import './content-page-template';
@import './landing-page-template';

cx-page-layout.LogoutPageTemplate,
cx-page-layout.LoginPageTemplate,
cx-page-layout.PasswordResetPageTemplate,
cx-page-layout.RegistrationPageTemplate {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
