cx-page-layout.BlankPageTemplate {
  header,
  footer {
    display: none !important;
  }

  /** Place and muck around with size of image, should be SVG */
  py-image {
    height: 100vh;
    width: 100vw;
    border-top: 5px solid var(--py-color-primary);
    background-color: var(--py-color-white);
    z-index: 2000;
    position: absolute;
    left: 0;
    top: 0;

    div.component-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      img {
        width: 60vw;
        height: auto;
      }

      @media (min-aspect-ratio: 4/7) {
        img {
          width: auto;
          height: 30vh;
        }
      }
    }
  }
}
