cx-page-layout.BrandLandingPageTemplate {
  @extend .container;
  @extend .row;
  align-content: center;
  padding-bottom: 120px;
  margin: 0 auto !important;
  justify-content: space-between;
  position: relative;

  py-paragraph {
    .paragraph-holder,
    p,
    h2 {
      padding: 0 !important;
    }
  }

  cx-page-slot.Section1,
  cx-page-slot.Section3 {
    @extend .col-12;
  }

  cx-page-slot.Section2A,
  cx-page-slot.Section2B {
    height: 250px;
    width: 49%;
  }

  cx-page-slot.Section1 {
    padding: 20px 0 !important;

    py-paragraph {
      p,
      h2 {
        color: white;
      }

      p {
        font-size: 1.6rem !important;
      }
    }
  }

  cx-page-slot.Section3 {
    text-align: center;
    margin-top: 20px;
  }

  cx-page-slot.Section2A,
  cx-page-slot.Section2B,
  cx-page-slot.Section3 {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 10px 40px -20px rgba(0, 0, 0, 0.9);
    border-radius: 5px;

    py-paragraph {
      h2 {
        font-size: 1.6rem !important;
      }
    }
  }

  cx-page-slot.Section1,
  cx-page-slot.Section2A,
  cx-page-slot.Section2B,
  cx-page-slot.Section3 {
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
      height: auto;
    }
  }

  @include media-breakpoint-down(sm) {
    cx-page-slot.Section2A,
    cx-page-slot.Section2B {
      width: 100%;
    }
    cx-page-slot.Section2B {
      margin-bottom: 0;
    }
  }
}
