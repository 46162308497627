cx-page-layout.CatalogPageTemplate {
  @include media-breakpoint-up(lg) {
    .py-max-width {
      padding-left: 45px;
      padding-right: 45px;
    }
  }

  .Section1 {
    padding-top: 0;
  }
}
