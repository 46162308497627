@import '../declarations';

cx-page-layout.MyPageTemplate {
  display: flex;
  flex-direction: row;
  max-width: 1600px;
  margin: 0 auto;

  .Section1A {
    flex-shrink: 0;
    width: calc(200px + 10%);
    max-width: 340px;
  }

  .Section1B {
    flex-grow: 1;
    max-width: 100%;
    min-width: 0px; // We need this, or the ellipsis in the dropdown component won't work.

    @include media-breakpoint-up(md) {
      padding-left: 20px;
      padding-right: 20px;
      border-left: 1px solid var(--py-color-grey-light);
    }

    @include media-breakpoint-up(lg) {
      padding-left: 45px;
      padding-right: 45px;
    }
  }
}
